.Premium_Wear_Container {
  height: 100%;
  width: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  // position: relative;
  .Premium_Wear_Title_Container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 80px;
    .hr {
      margin-top: 25px;
      height: 30px;
      width: 35%;
      border-top: 1px solid #cccccd;
    }
    .Premium_Wear_Title {
      font-size: 35px;
      color: #444444;
    }
  }
  .Premium_Wear_Tab {
    // width: 20vw;
    width: fit-content;
margin: auto;
    height: 8vh;
    background-color: #f7f7f8;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    // margin-left: 40%;
    margin-top: 50px;
    .tab-1,
    .tab-2 {
      color: #444444;
      font-size: 18px;
      height: 100%;
      width: 50%;
      padding: 0px 20px ;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.active {
        background-color: #ffffff;
        color: #a9772b;
        border: 0.5px solid #dfd6d6;
      }
    }
  }
}


@media screen and (max-width:1000px){
    .Premium_Wear_Container {
       
        .Premium_Wear_Title_Container {
         
          .hr {
            width: 20%;
            
          }
          .Premium_Wear_Title {
            font-size: 20px;
 
          }
        }
        .Premium_Wear_Tab {
          // width: 50vw;
          height: 8vh;
          background-color: #f7f7f8;
          padding: 10px;
         
          // margin-left: 25%;
        
         
        }
      }
}