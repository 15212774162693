.NetreBlueRim_Container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    padding: 50px;
    .NetreBlueRim-Section_Left {
        width: 50%;
        height: 100%;
        // border: 1px solid red;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        .Content_Container {
            height: 30%;
            position: relative;
            .Netre_Logo_left {
                margin-left: 20px;
                margin-top: 20px;
                height: 100px;
                width: 150px;
                // border:1px solid red
            }
            .Discover-btn {
                top: 40px;
                cursor: pointer;
                color: #a9772b;
                text-transform: uppercase;
                text-decoration: underline;
                right: 25px;
                position: absolute;
            }
            .Content-Para {
                margin-top: 0;
                margin-left: 20px;
                font-size: 15px;
                text-align: left;
                padding: 0;
                width: 60%;
                color: #444444;
                // border: 1px solid red;
            }
        }
        .Netre_glassImg_left {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 20vw;
            width: 20vw;
            img {
                transform: scaleX(-1);
            }
        }
    }
    .NetreBlueRim-Section_Right {
        width: 50%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        .Content_Container {
            height: 30%;
            position: relative;
            .Netre_Logo_right {
                margin-left: 20px;
                margin-top: 20px;
                height: 100px;
                width: 200px;
                img {
                    object-fit: contain;
                }
                // border:1px solid red
            }
            .Discover-btn {
                top: 40px;
                cursor: pointer;
                color: #a9772b;
                text-transform: uppercase;
                text-decoration: underline;
                right: 25px;
                position: absolute;
            }
            .Content-Para {
                margin-top: 0;
                margin-left: 20px;
                font-size: 15px;
                text-align: left;
                padding: 0;
                width: 60%;
                color: #444444;
                // border: 1px solid red;
            }
        }
        .Netre_glassImg_right {
            height: 20px;
            width: 100px;
        }
        .Netre_glassImg_right {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 20vw;
            width: 20vw;
            img {
                // transform: scaleX(-1);
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .NetreBlueRim_Container {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
        gap: 10px;
        .NetreBlueRim-Section_Left {
            width: 100%;
            .Content_Container {
                top: 4%;
                .Content-Para {
                    width: 90%;
                }
                .Discover-btn {
                    position: relative;
                    left: 20px;
                    // padding-bottom:30px !important;
                    top: 10px;
                }
            }
            .Netre_glassImg_left {
                height: 30vw;
                width: 30vw;
                left: 35%;
                bottom: 0px;
                img {
                    transform: rotate(90deg) scaleX(-1);
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .NetreBlueRim-Section_Right {
            width: 100%;
            position: relative;
            .Netre_glassImg_right {
                height: 30vw;
                width: 30vw;
                left: 35%;
                top: 4%;
                img {
                    transform: rotate(90deg);
                }
            }
            .Content_Container {
                bottom: 25%;
                position: absolute;
                flex-direction: row;
                margin-bottom: 0;
                .Content-Para {
                    width: 90%;
                }
                .Discover-btn {
                    position: relative;
                    left: 20px;
                    // padding-bottom:30px !important;
                    top: 10px;
                }
            }
        }
    }
}
@media (min-width: 425px) and (max-width: 768px) {
    .NetreBlueRim_Container {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
        gap: 10px;
        .NetreBlueRim-Section_Left {
            width: 100%;
            .Content_Container {
                top: 4%;

                .Content-Para {
                    width: 90%;
                }
                .Discover-btn {
                    position: relative;
                    left: 20px;
                    // padding-bottom:30px !important;
                    top: 10px;
                }
            }
            .Netre_glassImg_left {
                height: 30vw;
                width: 30vw;
                left: 35%;
                bottom: 0px;
                img {
                    transform: rotate(90deg) scaleX(-1);
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .NetreBlueRim-Section_Right {
            width: 100%;
            position: relative;
            .Netre_glassImg_right {
                height: 30vw;
                width: 30vw;
                left: 35%;
                top: 0px;
                img {
                    transform: rotate(90deg);
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
            .Content_Container {
                bottom: 25%;
                position: absolute;
                flex-direction: row;
                margin-bottom: 0;
                .Content-Para {
                    width: 90%;
                }
                .Discover-btn {
                    position: relative;
                    left: 20px;
                    // padding-bottom:30px !important;
                    top: 10px;
                }
            }
        }
    }
}
@media (min-width: 320px) and (max-width: 425px) {
    .NetreBlueRim_Container {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
        gap: 10px;
        .NetreBlueRim-Section_Left {
            width: 100%;
            .Content_Container {
                top: 4%;

                .Content-Para {
                    width: 90%;
                }
                .Discover-btn {
                    position: relative;
                    left: 20px;
                    // padding-bottom:30px !important;
                    top: 10px;
                }
            }
            .Netre_glassImg_left {
                height: 40vw;
                width: 40vw;
                left: 30%;
                bottom: 0;
                img {
                    transform: rotate(90deg) scaleX(-1);
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .NetreBlueRim-Section_Right {
            width: 100%;
            position: relative;
            .Netre_glassImg_right {
                height: 40vw;
                width: 40vw;
                left: 30%;
                top: 0;
                img {
                    transform: rotate(90deg);
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
            .Content_Container {
                bottom: 30%;
                position: absolute;
                flex-direction: row;
                margin-bottom: 0;
                .Content-Para {
                    width: 90%;
                }
                .Discover-btn {
                    position: relative;
                    left: 20px;
                    // padding-bottom:30px !important;
                    top: 10px;
                }
            }
        }
    }
}
