.Netre_Frames_Container{
    height: 100vh;
    width: 100%;
    // position: relative;
    .Netre_Tiles_Block{
        height: 100vh;
        width: 100%;
        position: relative;
        transition: all 0.5s ease-in-out;
        .overlay{
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            background-color: rgba(0,0,0,0.3);
            transition: all 0.5s ease-in-out;
            opacity: 0;
        }
        .Bottom_Circle{
            height: 15vw;
            width: 15vw;
            border-radius: 50%;
            background-color: #444444;
            position: absolute;
            left: 50%;
            top: 100%;
            transform: translate(-50% ,-50%);
            transition: all 0.5s ease-in-out;
            opacity: 0;
            z-index: 2;

        }
        .Circle_Container{
             position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 100vh;
            padding-top: 2%;
            overflow: hidden;
            transition: all 0.5s ease-in-out;
            opacity: 0;
           z-index: 3;
            .Circle-1{
                position: absolute;
                margin-left: 0;
                height: 100vw;
                width: 100vw;
                max-width: 100%;
                border-radius: 50%;
                border: 1px solid #F7F7F8;
                opacity: 45%;
            }
            .Circle-2{
                // position: absolute;
                margin: 10%;
                margin-left: 10%;
                height: 100vw;
                width: 80vw;
                border-radius: 50%;
                border: 1px solid #F7F7F8;
                opacity: 45%;
            }

        }
        .Content_Container{
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
            display:flex;
            justify-content:center;
            align-items:center;
            flex-direction: column;
            transition: all 0.5s ease-in-out;
            z-index: 3;
            opacity: 0;
            .Content_title{
                font-size: 3vw;
                color:#F7F7F8;
                opacity: 80%;

            }
            .Content_Para{
                color:#F7F7F8;
                opacity: 80%;
                margin-top: 10px;
                font-size: 1.2vw;
                text-transform: uppercase;
            }
        }
        .Netre_Tiles_Container{
        height: 100%;
        width: 100%;
        display: grid; 
        gap: 0;
        grid-template-columns: auto auto auto auto;
        .Netre_tile{
            height: 50vh;
            margin: 5px;
            width: 24vw;
            // border: 1px solid red;
            border-radius: 7%;
            transition: all 0.5s ease;
            background-size: 100vw 100vh;
            background-repeat: no-repeat;
            

        
        }
        .tile-1{
            background-position: 2% 0;
        }
        .tile-2{
            background-position: 35% 0;
        }
        .tile-3{
            background-position: 70% 0;
        }
        .tile-4{
            background-position: 100% 0;
        }
        .tile-5{
            background-position: 2% 100%;
        }
        .tile-6{
            background-position: 35% 99%;
        }
        .tile-7{
            background-position: 70% 100%;
        }
        .tile-8{
            background-position: 101% 100%;
        }
       
    }
    }
    .Netre_Frame_Shapes_Block{
        height: 100vh;
        width: 100%;
         position: relative;
        top: 0;
        border: 1px solid red;
        transition: all 0.5s ease-in-out;
        .Netre_Shapes_Container{
           position: relative; 
           height: 100%;
        width: 100%;
        .shape{
            position: absolute;
            
            border: 1px solid #444444;
            z-index: 1;
            transition: all 0.5 ease-in-out;
        }
        .triangle{
            -webkit-text-stroke: 1px #555;
            color: transparent;
            font-size: 200px;
        }
        .rectangle{
            height: 80px;
            width: 180px;
            transition: all 0.5 ease-in-out;
        }
        .Square{
            height: 100px;
            width: 100px;
            transition: all 0.5 ease-in-out;
        }
        .Oval{
            height: 150px;
            width: 70px;
            border-radius: 5rem;;
            transition: all 0.5 ease-in-out;
        }
        .Circle{
            height: 100px;
            width: 100px;
            border-radius: 50%;
            transition: all 0.5 ease-in-out;
        }
        .Tri-1{
            position: absolute;
            top:8%;
            left: 30%;
            transform: rotate(-20deg);
            // background-color: red;
        }

        .Tri-2{
            position: absolute;
            top:40%;
            transform: rotate(20deg);
            left: 60%;
        }

        .Tri-3{
            position: absolute;
            top:60%;
            left: 20%;
        }

        .Tri-4{
            position: absolute;
            top:-12%;
            left: 20%;
            transform: rotate(28deg);
        }


        .Tri-5{
            position: absolute;
            top:-12%;
            left: 70%;
            transform: rotate(25deg);
        }

        .Tri-6{
            position: absolute;
            top:75%;
            left: 80%;
            transform: rotate(25deg);
        }
        .Rec-1{
            top:25%;
            left: 58%;
            transform: rotate(-30deg);
        }
        .Rec-2{
            top:85%;
            left: 92%;
            transform: rotate(-30deg);
        }
        .Rec-3{
            top:85%;
            left: 10%;
            transform: rotate(-30deg);
            // background-color: red;
        }
        .Rec-4{
            top:3%;
            left: 5%;
            transform: rotate(40deg);
        }
        .Sq-1{
            top:25%;
            left: 10%;
        }
        .Sq-2{
            top:80%;
            left: 30%;
        }
        .Sq-3{
            top:80%;
            left: 30%;
        }
        .Sq-4{
            top:80%;
            left: 30%;
        }
        .Ov-1{
            top:20%;
            left: 70%;
        }
        .Cr-1{
            top:10%;
            left: 50%;
        }
        }
    }
}