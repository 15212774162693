@use "../../../variables";
.categories,
.Favorites_Container,
.exchange-order,
.Premium_Wear_Container,
.retailer {
  .best-selling-frames-banner {
    height: 250px;
    position: relative;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .overlay {
      background: rgba($color: #ffffff, $alpha: 0.5);
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 36px;
    }
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    @media screen and (max-width: variables.$small) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    // grid-gap: 20px 20px;
    padding: 20px;
    .card {
      aspect-ratio: 11/10;
      width: 100%;
      // background: var(--secondary-bg-color);

      position: relative;
      .sold-out {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: white;
        opacity: 0.75;
        display: flex;
        justify-content: center;
        align-items: start;
        padding-top: 15%;
        .div {
          color: var(--text-color-50);
          // transform: rotate(-45deg);
          transform-origin: center;
          font-size: 20px;
        }
      }
      .quantity {
        position: absolute;
        bottom: 20px;
        right: 20px;
        @media screen and (max-width: variables.$small) {
          right: 10px;
        }
        .label {
          font-size: 12px;
          margin-bottom: 5px;
          @media screen and (max-width: variables.$small) {
            display: none;
          }
        }
        select {
          border-radius: 1px;
          background: var(--primary-bg-color);
          border: none;
          padding: 10px;
          font-size: 14px;
          @media screen and (max-width: variables.$small) {
            padding: 5px;
            font-size: 10px;
          }
        }
      }
      .top {
        height: 15%;
        width: 100%;
        display: flex;
        justify-content: end;
        align-items: start;
        .new-arrival-label {
          // color: var(--color2);
          font-size: 14px;
          font-weight: 700;
          @media screen and (max-width: variables.$small) {
            font-size: 8px;
          }
        }
        .new-arrival-label {
          --f: 0.5em; /* control the folded part*/
          --r: 0.8em; /* control the ribbon shape */

          position: absolute;
          padding-inline: 0.25em;
          border-bottom: var(--f) solid #0005;
          border-right: var(--r) solid #0000;
          clip-path: polygon(
            0 0,
            0 calc(100% - var(--f)),
            var(--f) 100%,
            var(--f) calc(100% - var(--f)),
            100% calc(100% - var(--f)),
            calc(100% - var(--r)) calc(50% - var(--f) / 2),
            100% 0
          );
        }
        svg {
          height: 100%;
          width: 10%;
          path {
            // fill: var(--text-color);
          }
        }
        .filled {
          height: 100%;
          width: 10%;
          path {
            // fill: var(--color2);
          }
        }
        .count {
          color: var(--color2);
          font-size: 20px;
        }
        .hide {
          visibility: hidden;
        }
      }
      .middle {
        height: 70%;
        width: 100%;
        position: relative;
        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: contain;
          vertical-align: middle;
        }
      }
      .bottom {
        // height: 15%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        // gap: 5px;
        .title {
          font-size: 16px;
          display: inline-block;
          width: calc(100%);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0px 0;
          @media screen and (max-width: variables.$small) {
            font-size: 12px;
          }
        }
        .sku {
          font-size: 14px;
          display: inline-block;
          width: calc(100%);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          visibility: hidden;
          cursor: pointer;
          @media screen and (max-width: variables.$small) {
            font-size: 12px;
          }
        }
        .qty-present {
          width: calc(70%);
        }
        .show {
          visibility: visible;
        }
      }
    }
    .category-banner-1 {
      grid-area: 2/2/3/4;
      aspect-ratio: unset;
      padding: 0;
      @media screen and (max-width: variables.$small) {
        grid-area: 5/1/6/3;
        aspect-ratio: 2/1;
      }
      .img-box {
        height: 100%;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        vertical-align: middle;
        position: absolute;
      }
    }
    .category-banner-2 {
      grid-area: 7/1/8/3;
      aspect-ratio: unset;
      padding: 0;
      @media screen and (max-width: variables.$small) {
        grid-area: 10/1/11/3;
        aspect-ratio: 2/1;
      }
      .img-box {
        height: 100%;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        vertical-align: middle;
        position: absolute;
      }
    }
    .add_to_bag_text {
      color: #ac772b;
      font-weight: 600;
      cursor: pointer;
      margin-top: 5px;
    }
    @media (min-width: 240px) and (max-width: 425px) {
      .add_to_bag_text {
        font-size: 12px;
      }
    }
  }
  .filter-nav {
    margin: 20px 20px 0 20px;
    .sort-filter-btn {
      // color: var(--color2);
      cursor: pointer;
    }
  }
  .filter-side-panel {
    display: none;
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba($color: #000000, $alpha: 0.5);
      z-index: 1000;
    }
    .panel {
      position: fixed;
      top: 0;
      right: 0;
      height: 100%;
      width: 450px;
      background: var(--primary-bg-color);
      z-index: 1001;
      padding: 20px;
      display: flex;
      flex-direction: column;
      max-width: 100%;
      .results-btn {
        padding: 10px;
        margin-top: 20px;
        border: 1px solid var(--text-color);
        display: flex;
        justify-content: center;
        font-size: 12px;
        cursor: pointer;
      }
      .filter-body {
        flex-grow: 1;
        overflow-y: auto;
        .title {
          font-size: 16px;
          // margin-bottom: 10px;
        }
        .sortby {
          margin-bottom: 15px;
          .sortby-item {
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
            padding: 8px 10px;
            border: 1px solid var(--text-color);
            font-size: 12px;
          }
          .sortby-item-active {
            background: var(--text-color);
            color: var(--primary-bg-color);
          }
        }
        .filterby {
          .clear-all {
            cursor: pointer;
            font-size: 14px;
            text-decoration: underline;
          }
          .set-my-filter {
            border: 1px solid var(--color2);
            color: var(--color2);
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
            padding: 8px 10px;
            font-size: 12px;
          }
          .attribute-filters {
            .attribute-filter {
              .filter-items {
                .check-container {
                  margin: 10px;
                  label {
                    margin-left: 10px;
                  }
                }
              }
            }
          }
        }
      }
      /* Hide scrollbar for Chrome, Safari and Opera */
      .filter-body::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .filter-body {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }
  }
  .show {
    display: block;
  }
}
