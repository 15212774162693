:root {
  --primary-bg-color: #ffffff;
  --secondary-bg-color: #f7f7f8;
  --text-color: #444444;
  --text-color-50: #44444480;
  --color1: #959595;
  --color2: #a9772b;
  --color3: #707070;
  --color4: #ffffff;
  --color5: rgba(68, 68, 68, 1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "mont-regular";
  // color: var(--text-color);
  // outline: 1px solid red;
}

html,
body {
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  // color: var(--primary-bg-color);
  overflow-x: hidden;
}

.bg-1 {
  background: var(--primary-bg-color);
}
.bg-2 {
  background: var(--primary-bg-color);
}
.text-color {
  color: var(--text-color);
}
.color-2 {
  color: var(--color2);
}
.underline {
  text-decoration: underline;
}

@font-face {
  font-family: "mont-regular";
  src: url("./assets/fonts/Mont/Mont-Regular.otf");
}
.mont-regular {
  font-family: "mont-regular";
}
@font-face {
  font-family: "mont-light";
  src: url("./assets/fonts/Mont/Mont-ExtraLight.otf");
}
.mont-light {
  font-family: "mont-light";
}
@font-face {
  font-family: "mont-bold";
  src: url("./assets/fonts/Mont/Mont-Bold.otf");
}
.mont-bold {
  font-family: "mont-bold";
}

@font-face {
  font-family: "font-sabon";
  src: url("./assets/fonts/Sabon Roman/Sabon Roman.ttf");
}
.font-sabon {
  font-family: "font-sabon";
}

@font-face {
  font-family: sabon;
  src: url("./assets/fonts/sabon/Sabon.ttf");
}
.sabon {
  font-family: sabon;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #44444480;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #444444;
}

.hide_scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.scroll-container {
  display: flex;
  overflow-x: auto;
  cursor: grab;
  user-select: none;
}

.scroll-container:active {
  cursor: grabbing;
}

/* follow this link to get icons */
/* https://fonts.google.com/icons?selected=Material+Symbols+Outlined:search:FILL@0;wght@200;GRAD@0;opsz@24&icon.platform=web */
/* make a div with classname material-symbols-outlined and add name of the icon inside*/
/* ex:  <div className="material-symbols-outlined">search</div> */
.material-symbols-outlined {
  font-variation-settings:
    "FILL" 0,
    "wght" 200,
    "GRAD" 0,
    "opsz" 24;
  font-size: 32px;
  color: var(--text-color);
}
.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
/* grid */
.d-grid {
  display: grid;
}
.grid-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

/* position */
.p-a {
  position: absolute;
}
.p-r {
  position: relative;
}
.p-f {
  position: fixed;
}
.t-0 {
  top: 0;
}
.l-0 {
  left: 0;
}
.r-0 {
  right: 0;
}
.b-0 {
  bottom: 0;
}
.t-50 {
  top: 50%;
}
.l-50 {
  left: 50%;
}
.t-center {
  transform: translate(-50%, -50%);
}

/* flex */
.d-flex {
  display: flex;
}
.j-c {
  justify-content: center;
}
.a-c {
  align-items: center;
}
.j-e {
  justify-content: end;
}
.j-sb{
  justify-content: space-between;
}
.g-10 {
  gap: 10px;
}

/* padding */
.p-hr {
  padding-left: 15px;
  padding-right: 15px;
}
.p-vr {
  padding-top: 15px;
  padding-bottom: 15px;
}
.p-all {
  padding: 15px;
}
.pointer {
  cursor: pointer;
}
.link {
  text-decoration: none;
}
.no_data_found_main {
  width: 100%;
  padding: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: rgba(68, 68, 68, 0.5);
}
.ac_arrow_ic {
  width: 20px;
}

.ac_tr_arrow_ic {
  transition: all;
  transform: rotate(180deg);
}

// Progress Steps

.events time::after {
  content: "";
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  transform: translateX(50%);
  border-radius: 50%;
  background: #fff;
  border: 1px #ccc solid;
  width: 0.8em;
  height: 0.8em;
}

.events span::before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  height: 100%;
  border-left: 1px #ccc solid;
}

.events .last-item span::before {
  display: none;
}

/* Completed step styling with check mark */
.events time::after {
  content: "\2713"; /* Unicode character for check mark */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-color: #4caf50;
  color: #4caf50;
  font-size: 1em;
  font-weight: bold;
}

.events span::before {
  border-left-color: #4caf50;
}
.last_upper{
  border-left-color: #4caf50;
}
.events .completed span {
  color: #4caf50; /* Optional: Change text color for completed steps */
}
.accordion-item {
  padding: 1rem 0px;
  border-bottom: 1px solid #2d3648;
}
.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
}
.accordion-content {
  padding: 1rem 0px 0px 0px;
}
.grecaptcha-badge { 
  visibility: hidden !important;
}