.BrowseWiseFaceShape_Container {
  height: fit-content;
  width: 100%;

  background-color: #444444;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .BrowseWiseFaceShape_Title {
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2%;
    padding-bottom: 3%;

    h1 {
      font-size: 50px;
      color: #ffffff;
      font-weight: 400;
    }
  }
  .BrowseWiseFaceShape_Block {
    // height:100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 50px 20px;
    gap: 50px;
    .BrowseWiseFaceShape_card {
      height: 22vw;
      width: 20vw;
      background-color: #f7f7f8;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      position: relative;
      z-index: 2;
      .Overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        z-index: 5;
        &::after {
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8),
            rgba(0, 0, 0, 0.5) 26%,
            rgba(0, 0, 0, 0.12) 36%,
            rgba(255, 255, 255, 0.1) 55%,
            transparent 90%
          );
          bottom: 0;
          content: "";
          left: 0;
          position: absolute;
          right: 0;
          top: 0;

          transition: all 0.4s ease;
        }
      }
      .Card_img_Overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition:
          transform 0.4s ease,
          width 0.4s ease,
          height 0.4s ease,
          opacity 0.4s ease;
        z-index: 3;
        img {
          object-fit: cover;
          transition:
            transform 0.4s ease,
            width 0.4s ease,
            height 0.4s ease;
        }
      }
      .Card_img {
        height: 75%;
        width: 100%;
        img {
          object-fit: contain;
        }
      }

      &:hover .Card_img {
        background-color: #bdbfbd;
        img {
          display: none;
        }
      }

      .Card_Title {
        text-transform: capitalize;
        color: #444444;
        font-size: 24px;
        height: 25%;
        width: 90%;
        border-top: 1px solid #908a8a;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover .Card_img_Overlay {
        opacity: 1;
        transform: translateY(-20%);
        img {
          height: 120%;
          z-index: 3;
        }
      }
      &:hover .Card_Title span {
        color: #ffffff;
        z-index: 6;
      }
      &:hover .Overlay {
        opacity: 1;
      }
    }
  }
  .See_All_Frame_btn {
    width: fit-content;
    text-transform: uppercase;
    // font-size: 18px;
    border: 1px solid #f7f7f8;
    color: #f7f7f8;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

@media screen and (max-width: 1000px) {
  .BrowseWiseFaceShape_Container {
    height: fit-content;
    // width: 100%;
    .breakpoint__medium-down {
      overflow-x: scroll;
      align-self: flex-start;
      &::-webkit-scrollbar {
        width: 0;

        display: none; /* Hide the scrollbar on Webkit based browsers (Chrome, Safari, etc) */
        // -webkit-overflow-scrolling: touch; /* On touch screens the content continues to scroll for a while after finishing the scroll gesture */
      }
    }
    .carousel {
      position: relative;
      width: 162.5%;
      // padding: 50px 0px;
      height: fit-content;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      scroll-behavior: smooth;
      // padding:10px 0;
      padding-left: 10px;
      -webkit-overflow-scrolling: touch;
      .BrowseWiseFaceShape_card {
        height: 60vw;
        width: 55vw;
        margin: 10px;
        background-color: #f7f7f8;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        position: relative;
        z-index: 2;
        .Overlay {
          position: absolute;
          height: 100%;
          width: 100%;
          opacity: 0;
          z-index: 5;
          &::after {
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.8),
              rgba(0, 0, 0, 0.5) 26%,
              rgba(0, 0, 0, 0.12) 36%,
              rgba(255, 255, 255, 0.1) 55%,
              transparent 90%
            );
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            right: 0;
            top: 0;

            transition: all 0.4s ease;
          }
        }
        .Card_img_Overlay {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
          transition:
            transform 0.4s ease,
            width 0.4s ease,
            height 0.4s ease,
            opacity 0.4s ease;
          // background: rgb(0,0,0);
          z-index: 3;
          img {
            object-fit: cover;
            transition:
              transform 0.4s ease,
              width 0.4s ease,
              height 0.4s ease;
          }
        }
        .Card_img {
          height: 75%;
          width: 100%;
          img {
            object-fit: contain;
          }
          &:hover .Card_img {
            background-color: #bdbfbd;
            img {
              display: none;
            }
          }
        }
        .Card_Title {
          text-transform: capitalize;
          color: #444444;
          font-size: 24px;
          height: 25%;
          width: 90%;
          border-top: 1px solid #908a8a;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .carousel::-webkit-scrollbar {
      display: none;
    }

    .BrowseWiseFaceShape_Title h1 {
      font-size: 25px;
    }
  }
}
