.retailer {
    .BrowseGlasses {
        .heading {
            text-align: center;
            padding: 80px 0 0 0;
            font-size: 32px;
        }
    }

    @media screen and (max-width: 1000px) {
        .BrowseGlasses {
            .heading {
                text-align: center;
                padding: 80px 0 0 0;
                font-size: 32px;
            }
            .slider {
                max-width: 100vw;
                width: 100vw;
                display: block;
                gap: 0;
                .swiper {
                    .swiper-wrapper {
                        .swiper-slide {
                            .slider-item {
                                width: 80vw;
                                height: 50vh;
                                padding-top: 50px;
                                overflow: hidden;
                                position: relative;
                                top: 0;
                                left: 0;
                                // border: 1px solid red;
                                cursor: pointer;
                                .img {
                                    position: absolute;
                                    bottom: 0;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    height: 100%;
                                    width: 100%;
                                    vertical-align: middle;
                                    object-fit: contain;
                                    object-position: 50% 100%;
                                    transition:
                                        transform 0.4s ease,
                                        width 0.4s ease,
                                        height 0.4s ease;
                                    z-index: 2;
                                }
                                .img-back {
                                    position: absolute;
                                    bottom: 0;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    height: 100%;
                                    width: 100%;
                                    vertical-align: middle;
                                    object-fit: contain;
                                    object-position: 50% 100%;
                                    z-index: 1;
                                }
                                .overlay {
                                    position: absolute;
                                    height: 15%;
                                    width: 100%;
                                    background: transparent linear-gradient(180deg, #49474700 0%, #000000c9 100%) 0% 0%
                                        no-repeat padding-box;
                                    bottom: 0;
                                    left: 0;
                                    z-index: 2;
                                    transition: all 0.4s ease-in-out;
                                }
                                .text {
                                    position: absolute;
                                    top: 100%;
                                    left: 50%;
                                    z-index: 3;
                                    color: var(--color4);
                                    transform: translateX(-50%) translateY(-135%);
                                    text-transform: uppercase;
                                    padding: 10px;
                                    border: 1px solid var(--color4);
                                    transition: all 0.4s ease-in-out;
                                    font-size: 14px;
                                }
                            }
                            .slider-item:hover {
                                // .img{
                                //     transform: translateX(-50%); /* Scale up by 10% on hover */
                                //     height: 120%;
                                //     // width: 120%;
                                // }
                                .overlay {
                                }
                                .text {
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .cover {
        position: relative;
        padding: 0px 30px;
        // margin-top: 100px;
        .left {
            position: absolute;
            left: 0;
            top: 100%;
            transform: translateY(-40%);
        }

        .right {
            position: absolute;
            right: 0;
            top: 100%;
            transform: translateY(-40%);
        }

        .scroll-images {
            position: relative;
            width: 100%;
            padding: 40px 0px;
            height: auto;
            display: flex;
            flex-wrap: nowrap;
            overflow-x: scroll;
            overflow-y: hidden;
            scroll-behavior: smooth;
            -webkit-overflow-scrolling: touch;
        }

        .child {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            // min-width: 25vw;
            // height: 28vw;
            padding: 0px 15px;
            margin: 1px 10px;
            background-color: #f7f7f8;
            border: 1px solid #f7f7f8;
            overflow: hidden;
            position: relative;
            .material-symbols-outlined {
                position: absolute;
                right: 10px;
                top: 10px;
            }
            .Product_Price {
                color: #a9772b;
                margin-top: 10px;
                margin-bottom: 20px;
            }

            // -webkit-box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);;
            // box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);;
        }

        .child img,
        .child > svg {
            object-fit: contain;
            // background: #03A9F4;
        }

        .scroll-images::-webkit-scrollbar {
            width: 5px;
            height: 2px;
            border-radius: 10px;
            background-color: #e6dcdc;
            opacity: 37%;
        }

        .scroll-images::-webkit-scrollbar-thumb {
            background-color: #a9772b;
        }

        button {
            background-color: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            font-size: 25px;
        }

        @media screen and (max-width: 1000px) {
            // .child {
            //     min-width: 45vw;
            //     height: 58vw;
            // }
            .cover {
                padding: 0 10px;
            }
        }
        @media screen and (max-width: 700px) {
            // .child {
            //     min-width: 45vw;
            //     height: 80vw;
            // }
        }
    }
}
