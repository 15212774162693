.shopping_bag_main {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 0px 25px 25px 25px;

  .header_div {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-weight: 700;
    font-family: sabon;
    border-bottom: 1px solid #444444;
    text-wrap: nowrap;
  }
  .shopping_tray_div {
    padding-bottom: 10px;
    cursor: pointer;
  }
  .trial_tray_div {
    padding-bottom: 10px;
    margin-left: 35px;
    cursor: pointer;
  }
  .active_header {
    border-bottom: 2px solid #ac772b;
  }
  .shopping_tray_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .shopping_tray_one {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .left_top_div {
    // width: 60%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
  .product_img_div {
    width: 400px;
    height: 300px;
    object-fit: contain;
    overflow: hidden;
    background-color: #f7f7f8;
  }
  .product_img {
    width: 400px;
    height: 300px;
    object-fit: contain;
  }

  .product_details_card_one {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
  }
  .product_card_title {
    font-weight: 600;
    color: #2d3648;
  }
  .product_details_card_two {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
  .product_card_desc {
    font-weight: 600;
  }
  .product_details_card_three {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
  }

  .add_fav_text {
    text-decoration: underline;
    font-weight: 600;
    font-size: 18px;
    color: #444444;
    cursor: pointer;
    user-select: none;
  }
  .delete_ic {
    width: 24px;
    cursor: pointer;
  }
  .delivery_time {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
  }
  .radio_selection_section {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
  .selection_title_one {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(68, 68, 68, 0.5);
  }
  .radio_heading_text {
    color: #ac772b;
    font-weight: 700;
    font-size: 25px;
    cursor: pointer;
  }
  .radio_btn {
    accent-color: #444444;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  .radio_desc {
    margin-top: 15px;
  }
  .gift_message {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .gift_message_textarea {
    resize: none;
    margin-top: 5px;
    border: 1px solid rgba(68, 68, 68, 0.5);
    padding: 10px;
    font-size: 18px;
    outline: none;
  }

  .right_bottom_div {
    width: 30%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin-top: 25px;
  }

  .checkout_card_main {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: #f7f7f8;
  }
  .checkout_card_heading {
    font: 600;
    font-size: 20px;
  }
  .title_one_div {
    width: 100%;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title_two_div {
    width: 100%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .bill_title {
    font-size: 16px;
    color: #444444;
  }
  .offers_div {
    display: flex;
    // flex-direction: column;
    overflow-x: auto;
  }

  .offers_label {
    font-size: 16px;
    color: #444444;
    font-weight: 700;
    margin-top: 15px;
  }
  .offer_chip_div {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .offer_chip {
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: #ac772b;
    border-radius: 5px;
  }
  .offer_chip_title {
    color: white;
    font-size: 14px;
    font-weight: 500;
    margin-left: 5px;
    margin-right: 5px;
    text-wrap: nowrap;
  }
  .view_offers {
    color: #ac772b;
    text-decoration: underline;
    margin-top: 15px;
    cursor: pointer;
  }
  .hr_checkout {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .total_div {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .total_title {
    font-size: 16px;
    color: #444444;
    font-weight: 700;
  }
  .checkout_btn {
    text-transform: uppercase;
    font-size: 16px;
    color: white;
    font-weight: 700;
    width: 100%;
    padding: 8px 0px;
    margin-top: 15px;
    background-color: #444444;
    outline: none;
    border: none;
    cursor: pointer;
  }
  .experience_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  .experience_heder {
    color: #ac772b;
    font-size: 20px;
    margin-bottom: 25px;
  }

  .box_div {
    width: 60%;
    background-color: #f7f7f8;
    height: 400px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .box_title {
    width: 300px;
    font-size: 24px;
    color: #ac772b;
    font-weight: 700;
  }
  .box_img {
    width: 300px;
  }

  @media (min-width: 425px) and (max-width: 768px) {
    .left_top_div {
      width: 100%;
    }
    .right_bottom_div {
      width: 100%;
    }
    .radio_selection_section {
      width: 100%;
    }
    .product_img_div {
      width: 200px;
      height: 150px;
    }
    .product_img {
      width: 200px;
      height: 150px;
    }

    .add_fav_text {
      font-size: 16px;
    }
    .action_div {
      padding-bottom: 10px;
      margin-top: 10px;
    }
    .delivery_time {
      margin-top: 1px;
    }
    .box_div {
      width: 100%;
      padding: 10px;
    }
  }
  @media (min-width: 240px) and (max-width: 425px) {
    .left_top_div {
      width: 100%;
    }
    .right_bottom_div {
      width: 100%;
    }
    .radio_selection_section {
      width: 100%;
    }
    .product_img_div {
      width: 130px;
      height: 150px;
    }
    .product_img {
      width: 130px;
      height: 150px;
    }
    .add_fav_text {
      font-size: 14px;
    }
    .action_div {
      padding-bottom: 5px;
      margin-top: 5px;
    }
    .delivery_time {
      font-size: 10px;
      margin-top: 1px;
    }
    .box_div {
      width: 100%;
      padding: 10px;
    }
    .box_img {
      width: 200px;
    }
    .header_div {
      width: 100%;
      font-size: 20px;
      border-bottom: 1px solid #444444;
    }
  }
}
