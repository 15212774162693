.loginSignup {
  .main-box {
    // display: flex;
    // justify-content: center;
    // align-items: start;
    background-color: var(--primary-bg-color);
    padding: 10px 15%;
    color: var(--text-color);
  }

  .login-box,
  .signup-box {
    flex: 1;
    // max-width: 400px;
    width: 100%;
    box-sizing: border-box;
  }
  .lg_login {
    border-right: 2px solid #ac772b;
  }
  .login-form input {
    background-color: transparent;
    // border: none;
    border-bottom: 1px solid var(--text-color-50);
    outline: none;
    padding: 8px 0;
  }
  input::placeholder {
    color: var(--color1);
  }

  .login-text {
    margin-bottom: 20px;
    font-family: "mont-regular";
  }
  .login-title {
    margin-bottom: 10px;
    font-weight: 500;
  }
  .login-btn {
    background-color: transparent;
    color: black;
    cursor: pointer;
    border: none;
    padding: 10px 0;
  }
  .phone-input-container-signup {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
  }
  .phone-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
  }
  .send-otp-btn-login {
    background-color: transparent;
    color: var(--color2);
    margin-left: -35px;
    cursor: pointer;
    border: none;
    font-family: "mont-regular";
  }
  .send-otp-btn-signup {
    background-color: transparent;
    color: var(--color2);
    margin-left: -35px;
    cursor: pointer;
    border: none;
    padding: 8px 0;
    margin-bottom: 18px;
    font-family: "mont-regular";
  }

  .signup-form input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid var(--text-color-50);
    outline: none;
    padding: 8px 0;
    // margin-bottom: 20px;
    width: 100%;
  }

  .signup-text {
    margin-bottom: 40px;
    font-family: "mont-regular";
  }

  .signup-title {
    margin-bottom: 10px;
    font-weight: 500;
  }

  .signup-btn {
    background-color: transparent;
    color: black;
    cursor: pointer;
    padding: 10px 0;
    display: inline-block;
    white-space: nowrap;
    border: none;
    width: 100%;

    border: 1px solid var(--color1);
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-top: 20px;
  }

  .login-btn-container {
    border: 1px solid var(--color1);
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
  }
  .need-assistance {
    justify-content: center;
    margin-top: 150px;
  }

  .need-assistance-title {
    color: var(--color2);
    text-decoration: none;
    font-family: "mont-regular";
    margin-bottom: 10px;
  }
  .contactus {
    text-decoration: underline;
    margin-bottom: 5px;
    font-family: "mont-regular";
  }

  .phone-icon {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    font-family: "mont-regular";
  }
  .email-icon {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    font-family: "mont-regular";
  }
  .error-msg {
    color: red;
    font-family: "mont-light";
    font-size: 12px;
  }

  @media screen and (max-width: 430px) {
    .main-box {
      flex-direction: column;
      padding: 0 4%;
    }
    .signup-form {
      width: 100%;
      padding: 0;
    }
    .login-title {
      text-align: center;
    }
    .login-text {
      text-align: center;
    }
    .signup-title {
      text-align: center;
    }
    .signup-text {
      text-align: center;
    }
    .login-form input {
      align-items: center;
    }
    .login-btn {
      width: 100%;
    }
    .mobile-signup-link {
      text-align: center;
      margin-top: 8%;
      text-decoration: underline;
    }
    .or-container {
      text-align: center;
      margin-top: 10%;
    }
    .need-assistance {
      margin-top: 10%;
    }
    .login-btn-container {
      width: 100%;
    }
  }

  @media screen and (min-width: 430px) {
    .mobile-or-signup {
      display: none;
    }
    .or-container {
      display: none;
    }
  }
}
