.Product_Container{
    height: 70vh;
    width: 29.9vw;
    background-color: #F7F7F8;
    position: relative;
    cursor: pointer;
    .product_sold_out{
        position: relative;
        height: 100%;
        width: 100%;display: flex;
        justify-content: center;
        align-items: center;
    .Sold_overlay{
        position: absolute;
        top:0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #F7F7F8;
        
        opacity: 68%; /* Black background with opacity */
        z-index: 9;
       
    }
    .Sold_Out_btn{
        padding: 12px 35px;
        font-size: 18px;
        z-index: 10;
        color:#A9772B;
        font-weight: 600;
        opacity: 100% !important;
        border: 2px solid #A9772B;
    }
    }
    .Whislist-icon{
        height: 10%;
        width: 10%;;
        margin-top: 3%;
        text-align: center;
        position: absolute;
        top:0;
        left: 87%;
       
        cursor: pointer;
        // border: 1px solid red;
        
    }
    .Product-img{
        position: absolute;
        top:0;
        width: 100%;
        height: 100%;
    }
    .Product_Des{
        height: 10%;
        position: absolute;
        bottom: 8%;
        line-height: 1.8;
        padding-left: 6%;
        .Product_name{
            font-size: 1.5vw;
            color:#444444;
        }
        .Product_Price{
            font-size: 1.2vw;
            color:#A9772B;
        }
    }
}

@media screen and (max-width:1000px) {
    .Product_Container{
        height: 25vh;
        width: 48%;
        background-color: #F7F7F8;
        position: relative;
        cursor: pointer;
        .product_sold_out{
           
        .Sold_overlay{
            position: absolute;
            top:0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: #F7F7F8;
            
            opacity: 68%; /* Black background with opacity */
            z-index: 9;
           
        }
        .Sold_Out_btn{
            padding: 12px 25px;
            font-size: 12px;
            z-index: 10;
            color:#A9772B;
            font-weight: 600;
            opacity: 100% !important;
            border: 2px solid #A9772B;
        }
        }
        .Whislist-icon{
            height: 5%;
            width: 5%;;
            margin-top: 3%;
            text-align: center;
            position: absolute;
            top:0;
            left: 80%;
           
            cursor: pointer;
            // border: 1px solid red;
            
        }
        .Product-img{
            position: absolute;
            top:0;
            width: 100%;
            height: 100%;
        }
        .Product_Des{
            height: 10%;
            position: absolute;
            bottom: 8%;
            line-height: 1.8;
            padding-left: 6%;
            .Product_name{
                font-size: 12px;
                color:#444444;
            }
            .Product_Price{
                font-size: 10px;
                color:#A9772B;
            }
        }
    } 
}