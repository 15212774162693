.LenseOptions_Container{
// height: 100vh;
height: fit-content;
width: 100%;

.lense-title-container{
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    // flex-direction: column;
    .hr{
        width: 35%;
        margin-top: 10px;
        height: 10px;
        border-top : 2px solid #707070;
        opacity: 30%;
    }
    .title{
font-size: 30px;
    }
    
}
.contentWrapper{
    padding: 1%;
    height: 70vh;
    width: 100%;
    > :first-child{
        height: 100% !important;
    }
    img{
        height: 100%;
        width: 100%;
        object-fit: cover !important;
    }
}
.Tab_Container{
    height:10%;
    width:100%;
    display:flex;
    flex-wrap: wrap;
    justify-content:center;
    align-items: center;
    gap:5px;
    .tab{
        font-size: 1.1vw;
        padding: 0 30px ;
        border-right: 1px solid #707070;
        font-weight: 600;
        cursor: pointer;
        &:last-child{
            border: none;
        }
        &.active{
            color:#A9772B;
            text-decoration: underline;
        }
    }
}
}

@media screen and (max-width:1000px) {
    .LenseOptions_Container {
        height: 60vh;
        .contentWrapper {
            height: 50vh; 
        }
    }
    .LenseOptions_Container .Tab_Container .tab {
        font-size: 11px;
        padding: 0 5px;
        border-right: 1px solid #707070;
        font-weight: 600;
        cursor: pointer;
    }
    .LenseOptions_Container .lense-title-container {
        // height: 10%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    .LenseOptions_Container .lense-title-container .title {
        font-size: 15px;
    }
    .LenseOptions_Container .lense-title-container .hr {
        width: 28%;
       
    }
}