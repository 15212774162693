.Flexible_Frames_Container {
    height: 70vh;
    width: 100%;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;
    .Section_title_container {
        position: absolute;
        top: 6%;
        left: 50%;
        transform: translate(-50%, 0);

        .section-title {
            color: #a9772b;
            text-transform: uppercase;
            font-size: 18px;
            text-align: center;
        }
        .Section_Sub_title {
            margin-top: 2%;
            font-size: 35px;
            text-transform: capitalize;
        }
    }
    .try-now-btn {
        font-size: 16px;
        padding: 12px 55px;
        text-transform: uppercase;
        color: var(--text-color);
        border: 1px solid var(--text-color);
        display: inline-block;
        position: relative;
        z-index: 3;
        cursor: pointer;
        position: absolute;
        bottom: 3%;
        left: 50%;
        transform: translateX(-50%);
        .overlay {
            height: 50%;
            width: 50%;
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            transition: all 0.5s;
        }
    }
    .try-now-btn:hover {
        color: var(--primary-bg-color);
        .overlay {
            height: 100%;
            width: 100%;
            background: var(--text-color);
            border: 1px solid var(--text-color);
        }
    }
}

@media screen and (max-width: 1000px) {
    .Flexible_Frames_Container {
        // margin-top: 45vh;
        height: 40vh;
        .Section_title_container {
            .section-title {
                font-size: 14px;
            }

            .Section_Sub_title {
                font-size: 13px;
            }
        }
        .try-now-btn {
            font-size: 14px;
            padding: 6px 22px;
        }
    }
}
