.cover {
    position: relative;
    padding: 0px 30px;
    // margin-top: 100px;
    .left {
        position: absolute;
        left: 0;
        top: 100%;
        transform: translateY(-40%);
    }

    .right {
        position: absolute;
        right: 0;
        top: 100%;
        transform: translateY(-40%);
    }

    .scroll-images {
        position: relative;
        width: 100%;
        padding: 40px 0px;
        height: auto;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
    }

    .child {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        // min-width: 25vw;
        // height: 28vw;
        padding: 0px 15px;
        margin: 1px 10px;
        background-color: #f7f7f8;
        border: 1px solid #f7f7f8;
        overflow: hidden;
        position: relative;
        .material-symbols-outlined {
            position: absolute;
            right: 10px;
            top: 10px;
        }
        .Product_Price {
            color: #a9772b;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        // -webkit-box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);;
        // box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);;
    }

    .child img,
    .child > svg {
        object-fit: contain;
        // background: #03A9F4;
    }

    .scroll-images::-webkit-scrollbar {
        width: 5px;
        height: 2px;
        border-radius: 10px;
        background-color: #e6dcdc;
        opacity: 37%;
    }

    .scroll-images::-webkit-scrollbar-thumb {
        background-color: #a9772b;
    }

    button {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 25px;
    }

    @media screen and (max-width: 1000px) {
        // .child {
        //     min-width: 45vw;
        //     height: 58vw;
        // }
        .cover {
            padding: 0 10px;
        }
    }
    @media screen and (max-width: 700px) {
        // .child {
        //     min-width: 45vw;
        //     height: 80vw;
        // }
    }
}
