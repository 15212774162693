.my_bag_main {
  .shadow-md {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .my_bag_body::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .my_bag_body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .my_bag {
    width: 40%;
    height: 100dvh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: #fff;
    position: fixed;
    top: 0;
    right: -200%;
    z-index: 9999;
    transition: 0.5s;
    padding: 0px 25px 25px 25px;
    display: flex;
    flex-direction: column;
  }
  .my_bag.active {
    right: 0;
  }
  .my_bag_header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    padding: 0px 15px 15px 15px;
  }

  .my_bag_header_link {
    color: #ac772b;
    text-decoration: underline;
    width: 700;
    font-size: 18px;
    text-wrap: nowrap;
    cursor: pointer;
  }
  .my_bag_header_text {
    width: 700;
    font-size: 18px;
    text-wrap: nowrap;
    margin-left: 10px;
  }
  .my_bag_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
    z-index: 999;
  }
  .my_bag_overlay.active {
    opacity: 1;
    visibility: visible;
  }
  .my_bag_body {
    overflow-x: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 10px;
    flex-grow: 1;
  }
  .my_bag_bottom_main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .view_bag_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 20px;
    cursor: pointer;
  }
  .checkout_btn {
    margin-top: 10px;
    width: 100%;
    border: 1px solid black;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    cursor: pointer;
  }
  .my_bag_card_main {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .img_div {
    width: 200px;
    height: 170px;
    background-color: #f7f7f8;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
  }
  .my_bag_product_img {
    width: 140px;
    height: auto;
    object-fit: contain;
  }
  .my_bag_card_details {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
    font-size: 18px;
    font-weight: 600;
  }
  .my_bg_card_details_inner {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }

  .currency_symbol {
    font-weight: 600;
    font-size: 16px;
  }
  .quantity_text {
    margin-top: 20px;
    font-size: 15px;
    font-weight: 700;
  }
  .remove_text {
    color: #ac772b;
    text-decoration: underline;
    cursor: pointer;
  }
  @media (min-width: 240px) and (max-width: 768px) {
    .my_bag {
      width: 85%;
    }
  }
  @media (min-width: 240px) and (max-width: 425px) {
    .my_bag {
      width: 90%;
    }
    .my_bag_header_link {
      font-size: 12px;
    }
    .my_bag_header_text {
      font-size: 12px;
    }
    .my_bag_product_img {
      width: 100px;
    }
  }
}
