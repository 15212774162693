.What_We_Offer_Container{
    height: 100vh;
    width: 100%;
    .What_We_Offer_Title_Container{
        height: 20%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .What_We_Offer_title{
            color:#444444;
            font-size: 2.5vw;
            text-align: center;
        }
        .What_We_Offer_Para{
            margin-top: 20px;
            font-size: 1vw;
            line-height: 2;
            width: 33%;
            text-align:center;
        }

    }
    .What_We_Offer_Gallery{
        display: flex;
        justify-content: center;
        align-items: center;
        height:75%;
        padding: 2%;
        gap:30px;
        .item{
            height: 100%;
            width: 32%;
            // border: 1px solid red;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: end;
            overflow: hidden;
            .Overlay{
                position: absolute;
                opacity: 37%;
                height: 100%;
                width: 100%;
                z-index: 1;
                top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color:#000000
        }

              .try-now-btn{
                position: absolute;
                z-index: -1;
              bottom: 30px;
                font-size: 16px;
                padding: 12px 55px;
                text-transform: uppercase;
                color: #F7F7F8;
                border: 1px solid #F7F7F8;
                display: inline-block;
                position: relative;
                z-index: 3;
                cursor: pointer;
                .overlay{
                    height: 50%;
                    width: 50%;
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1 ;
                    transition: all 0.5s ;
                }
            }
            .try-now-btn:hover{
                color: var(--primary-bg-color);
                color: var(--text-color);
                .overlay{
                    height: 100%;
                    width: 100%;
                    background: #F7F7F8;
                    border: 1px solid var(--primary-bg-color);
                }
            }
            img{
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                transition: all 0.5s ease;
               
            }
            &:hover img{
                scale:1.1;
                transform:translateX(-20px)
            }
        }
    }
}

@media screen and (max-width:1000px) {
    .What_We_Offer_Container{
        height: 100%;
        .What_We_Offer_Title_Container{
            .What_We_Offer_Para {
                margin-top: 10px;
                margin-bottom: 20px;
                font-size: 14px;
                line-height: 2;
                width: 80%;
                text-align: center;
            }
         .What_We_Offer_title {
            font-size: 30px;
            text-align: center;
        }
    }
    .What_We_Offer_Gallery {
     flex-direction: column;
     .item{
        width: 100%;
        height: 50vh;
     }
    }
    }
}