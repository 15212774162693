.header{
 z-index: 999;
}
.top-links{
    background: var(--primary-bg-color);
    display: flex;
    justify-content: center;
    font-size: 12px;
    padding: 8px 0;
    a{
        border-right: 1px solid var(--color1);
        text-decoration: none;
        padding: 0px 10px;
    }
    //remove border from last child
    a:last-child{
        border-right: none;
    }
}
.header-top-child{
    margin: 0 25px;
    padding: 25px 0;
}

.header-right-icons .vertical-rule{
    width: 1px;
    border-right: 1px solid var(--text-color);
    height: 20px;
}
.header-right-icons .material-symbols-outlined{
    cursor: pointer;
    padding: 0 18px;
    height: 32px;
}
.header-right-icons .material-symbols-outlined:last-child{
    padding-right: 0;
}
.header-bottom{
    gap: 3%;
    padding: 20px 25px ;
    color: var(--text-color);
    border-top: 1px solid var(--color1);
    .items{
        cursor: pointer;
        font-size: 18px;
    }
}

@media screen and (max-width:1000px){
    .header{
        z-index: 999;
        width: 100vw;
        max-width: 100%;
       }
       .header-top-child{
           margin: 0 5px;
           padding: 20px 0;
        //    width: 100vw;
        .vertical-rule{
            width: 1px;
            border-right: 1px solid var(--text-color);
            height: 20px;
            
        }
        .material-symbols-outlined{
            cursor: pointer;
            padding: 0 8px;
            height: 32px;
        }
       }
       
       .header-right-icons .vertical-rule{
          
       }
       .header-right-icons .material-symbols-outlined{
           cursor: pointer;
           padding: 0 8px;
           height: 32px;
       }
       .header-right-icons .material-symbols-outlined:last-child{
           padding-right: 0;
       }
       .header-bottom{
           gap: 3%;
           padding: 20px 25px ;
           color: var(--text-color);
           border-top: 1px solid var(--color1);
           .items{
               cursor: pointer;
               font-size: 18px;
           }
       }
}