.sidebar_main {
  .shadow-md {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .sidebar_body::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .sidebar_body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .sidebar {
    width: 40%;
    height: 100vh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: #fff;
    position: fixed;
    top: 0;
    left: -200%;
    z-index: 9999;
    transition: 0.5s;
    // padding: 0px 25px 25px 25px;
    display: flex;
    flex-direction: column;
  }
  .sidebar.active {
    left: 0;
  }
  .sidebar_header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    padding: 0px 15px 15px 15px;
  }

  .sidebar_header_link {
    color: #ac772b;
    text-decoration: underline;
    width: 700;
    font-size: 18px;
    text-wrap: nowrap;
    cursor: pointer;
  }
  .sidebar_header_text {
    width: 700;
    font-size: 18px;
    text-wrap: nowrap;
    margin-left: 10px;
  }
  .sidebar_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000004d;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
    z-index: 999;
  }
  .sidebar_overlay.active {
    opacity: 1;
    visibility: visible;
  }
  .sidebar_body {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 10px;
    flex-grow: 1;
  }
  .items{
    padding: 10px 20px;
    width: 100%;
    cursor: pointer;
    margin-bottom: 20px;
    border-bottom: solid 1px #8B94B2;
  }
  .logo_div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid black;
    padding: 20px;
    margin-bottom: 10px;
  }
  .logo{
    width: 100px;
  }
  .close{
    width: 25px;
  }
  @media (min-width: 240px) and (max-width: 768px) {
    .sidebar {
      width: 85%;
    }
  }
}
