.breadcrumbs {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 14px;
    text-decoration: none;
    color: var(--color5);   
    padding: 20px 0px 20px 0px; 
    a{
        text-decoration: none;
    }
    }
    
    .separator {
    margin: 0 5px;
    }