.tryon_new{
    .Plus_Block{
        width: 100%;
        height: 100%;
        z-index: 1;
        position: relative;
        margin: 0;
        // border: 1px solid red;
        .Buy1Get1_btn{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
                font-size: 18px;
                padding: 12px 40px;
                text-transform: uppercase;
                color: var(--text-color);
                outline: 10px solid #EFE3D3;
                display: inline-block;
                position: relative;
                border: 1px solid black;
                z-index: 10;
                background-color: #FFFFFF;
                cursor: pointer;
                text-transform: uppercase;
        }
    &::after {
        content: " ";
        position: absolute;
        display: block;
        background-color: #EFE3D3;
        height: 20px;
        width: 100%;
        margin-top: -5px;
        top: 50%;
        left: 0;
        right: 5px;
        z-index: 9;
        // border: 1px solid red;
      }
      &::before {
        content: " ";
        position: absolute;
        display: block;
        background-color: #EFE3D3;
        width: 20px;
        margin-left: -5px;
        left: 50%;
        top: 0;
        // border: 1px solid red;
        bottom: 0;
        z-index: 9;
      }
    }
}



.Model_img{
    position: absolute;
    bottom: 3%;
    right: 1%;
    z-index: 3;
    height: 60%;
    width: 45%;
    object-fit: contain;
    object-position: 100% 100%;
}

.Buy1Get1_Block{
    position: absolute;
    // top: 15%;
    top:0;
    // padding:3% 2%;
background-position: center;
background-size: cover;
background-repeat: no-repeat;

    left: 1%;
    z-index: 1;
    width: 47%;
    height: 97%;

    

}
.content{
    position: absolute;
    // top: 15%;
    top:0;
    padding:3% 2%;
background-position: center;
background-size: cover;
background-repeat: no-repeat;

    left: 50%;
    z-index: 1;
    width: 49%;
    height: 97%;
    .text-1{
        color: var(--color2);
        text-transform: uppercase;
        font-size: 22px;
    }
    .text-2{
        color: var(--text-color);
        text-transform: capitalize;
        font-size: 34px;
        margin: 25px 0;
    }
    .hr{
        border-bottom: 1px solid var(--color3);
        width: 50%;
        opacity: 0.5;
        margin: 25px 0;
    }
    .text-3{
        color: var(--text-color);
        text-transform: capitalize;
        font-size: 16px;
        width: 70%;
        line-height: 1.8rem;
        margin: 25px 0;
    }
    .try-now-btn{
        font-size: 16px;
        padding: 12px 55px;
        text-transform: uppercase;
        color: var(--text-color);
        border: 1px solid var(--text-color);
        display: inline-block;
        position: relative;
        z-index: 3;
        cursor: pointer;
        .overlay{
            height: 50%;
            width: 50%;
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1 ;
            transition: all 0.5s ;
        }
    }
    .try-now-btn:hover{
        color: var(--primary-bg-color);
        .overlay{
            height: 100%;
            width: 100%;
            background:  var(--text-color);
            border: 1px solid var(--text-color);
        }
    }
}


@media screen and (max-width:1000px) {
    .content{
        position: absolute;
        // top: 15%;
        top:0;
        // padding:3% 2%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 8% 5%;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 70%;
        .try-now-btn {
            font-size: 16px;
            padding: 5px 25px;
            margin-top: 10px;
            
        }
        .text-1{
            font-size: 16px;
        }
        .text-2{
            font-size: 25px;
            margin: 10px 0;
        }
    }
    .Buy1Get1_Block {
        position: absolute;
        top: 72%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 70%;
    }
    .Model_img{
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
        height: 70%;
        width: 70%;
        object-fit: contain;
        object-position: 100% 100%;
    }
}