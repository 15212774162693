.tw_product_main {
  .Clustered-product {
    .Clustered-product-name {
      display: none;
      font-size: 14px;
      margin-top: 5px;
      padding: 2px 4px;
      width: max-content;
      color: var(--primary-bg-color);
      background: var(--text-color);
      border-radius: 5px;
      transform: translateX(calc(-50%));
    }
    &:hover {
      .Clustered-product-name {
        display: block;
      }
    }
  }
  .slick-track {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .slick-dots {
    background-color: red;
  }
  .slick-dots {
    background: var(--secondary-bg-color);
    bottom: 0;
    padding: 10px;
  }
  .slick-arrow {
  }

  .slick-next {
    width: 30px;
    height: 30px;
    z-index: 9;
    right: 0;
  }
  .slick-prev {
    width: 30px;
    height: 30px;
    z-index: 9;
    left: 0;
  }

  .slick-prev:before,
  .slick-next:before {
    color: gray;
    font-size: 20px;
    visibility: hidden;
  }
}

.Product_Page_Container {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  //   border: 1px solid red;
  overflow-y: hidden;

  .Product__Slider__Container {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f8;
    .Product__Thumbnail {
      width: 20%;
      height: 50%;
      // border: 1px solid red;
      transform: rotate(90deg);
      .carousel-cell {
        width: 100%;
        /* height: 200px; */
        margin-right: 10px;
        /* background: #8c8; */
        border-radius: 5px;
        counter-increment: carousel-cell;
      }

      .carousel-cell-1 {
        width: 20%;
        height: 50px;
        margin-right: 10px;
        /* background: #8c8; */
        border-radius: 5px;
        counter-increment: carousel-cell;
        //  border: 1px solid #707070;
        padding: 3px;
      }

      .carousel-cell-1-mobile {
        width: 60px;
        height: 100px;
        margin-right: 10px;
        /* background: #8c8; */
        border-radius: 5px;
        counter-increment: carousel-cell;
        // border: 1px solid #707070;
        padding: 3px;
      }

      .carousel-cell-1 img {
        height: 100%;
        width: 150%;
        object-fit: contain;
        transform: rotate(-90deg);
        // border-radius: 5px;
      }

      .carousel-cell-1-mobile img {
        // transform: rotate(0deg);
        border-radius: 5px;
      }
      .flickity-prev-next-button.previous {
        left: -50px;
      }
      .flickity-prev-next-button.next {
        right: -50px;
      }
    }
    .Product__Main__Image {
      width: 60%;
      height: 100%;
      // border: 1px solid red;
      .carousel-cell {
        width: 100%;
        /* height: 200px; */
        margin-right: 10px;
        /* background: #8c8; */
        border-radius: 5px;
        counter-increment: carousel-cell;
      }
      .carousel-main .carousel-cell {
        width: 100%;
        height: 360px;
        font-size: 5rem;
        aspect-ratio: 1/1;
        width: 100%;
        /* height: 200px; */
        margin-right: 10px;
        /* background: #8c8; */
        border-radius: 5px;
        counter-increment: carousel-cell;
        div {
          height: 100% !important;
          width: 100% !important;
          img {
            height: 100% !important;
            width: 100% !important;
            object-fit: contain;
            padding: 15px;
          }
        }
      }
    }
    .Product__Try__On {
      width: 20%;
      height: 100%;
      // border: 1px solid red;
      display: flex;
      justify-content: flex-start;
      align-items: start;
      gap: 30px;
      margin-top: 5%;
      flex-direction: column;
      .Product_Try_On_Btn {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 15px;
        color: rgba(172, 119, 43, 1);
        padding: 10px 30px;
        border: 1px solid rgba(172, 119, 43, 1);
        gap: 10px;
        cursor: pointer;
        .material-symbols-outlined {
          color: rgba(172, 119, 43, 1);
        }
      }
      .Product_Share_Btn {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 15px;
        color: #ffffff;
        padding: 10px 10px;
        border-radius: 50%;
        justify-self: flex-end;
        align-self: flex-end;
        margin-right: 45%;
        border: 1px solid rgba(172, 119, 43, 1);
        gap: 10px;
        background-color: rgba(172, 119, 43, 1);
        cursor: pointer;
        .material-symbols-outlined {
          color: #ffffff;
        }
      }
    }
  }

  ///** Product Details And Action Buttons *///
  .Product_Action_Btn_Container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(247, 247, 248, 1);
    flex-direction: column;
    padding-bottom: 2%;

    .Product_Action_Btn_Section {
      padding: 40px 0;
      width: 80%;
      // border: 10px solid red;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      display: flex;

      .Product__title {
        padding: 20px 16% 10px 20px;
        border-right: 1px solid rgba(172, 119, 43, 0.8);
      }
      .Product__Wishlist {
        padding: 20px;
        padding-right: 45%;
        font-size: 1vw;
        cursor: pointer;
        // border: 1px solid red;
        svg {
          height: 30px;
          width: 30px;
          path {
            fill: var(--text-color);
          }
        }
        .filled {
          path {
            fill: var(--color2);
          }
        }
      }
      .Product__Btn__Container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 10px;
        font-size: 1vw;
        .Add_Lens_btn {
          padding: 10px 20px;
          border: 1px solid rgba(68, 68, 68, 1);
          text-transform: uppercase;
          font-size: 14px;
          color: rgba(68, 68, 68, 1);
          cursor: pointer;
          font-size: 1vw;
        }
        .Add_To_Bag_btn {
          font-size: 1vw;
          padding: 10px 20px;
          border: 1px solid rgba(68, 68, 68, 1);
          text-transform: uppercase;
          font-size: 14px;
          color: rgba(255, 255, 255, 1);
          background-color: rgba(68, 68, 68, 1);
          cursor: pointer;
        }
      }
    }
    .Product_Action_Get_Delivered_Section {
      padding: 10px 0;
      width: 80%;

      // border: 10px solid red;
      background-color: rgba(247, 247, 248, 1);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 20px;
    }
  }

  ///** Product Tyr At Home And Store *///
  .Product__Try__Container {
    padding-bottom: 30px;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    background-color: rgba(247, 247, 248, 1);
    .Try__At__home {
      width: 39.8%;
      height: 100%;
      // border: 1px solid red;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);

      img {
        object-fit: cover;
        height: 100%;
      }
    }
    .Find__In__Store {
      height: 100%;
      // border: 1px solid red;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);

      width: 39.8%;
      img {
        object-fit: cover;
        height: 100%;
      }
    }
  }
  ///** Product Tyr At Home And Store *///
  .Product__Page__banner {
    height: 30vh;
    width: 100%;
    margin-bottom: 30px;
    img {
      object-fit: cover;
    }
  }

  .Product__Details__Container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid red;
    margin-bottom: 50px;
    .Product__Details__Baner {
      height: 60vh;
      width: 85%;
      img {
        height: 100%;
        width: 60%;
        object-fit: cover;
        //margin-top: -30px;
        //  border: 1px solid red;
        object-position: center;
      }
    }
    .Product__Details__Section {
      height: 100%;
      width: 50%;
      .Product__Details__Title {
        color: rgba(172, 119, 43, 1);
        font-size: 22px;
      }
      .Product__Details__Des {
        color: rgba(68, 68, 68, 1);
        font-size: 16px;
      }
      .Product__Details__Data_Container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: start;
        flex-direction: column;
        margin: 20px 0;
        .info-grid-title {
          font-size: 16px;
        }
        .Product__Details__Data {
          width: 70%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px 0;
          border-bottom: 1px solid rgba(68, 68, 68, 0.5);
          font-size: 16px;
        }
      }
    }
  }

  //Recently View

  .Recently__Viewed__Frames__title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 25px;
    color: rgba(172, 119, 43, 1);
    margin-bottom: 20px;
  }
  .Recently__Viewed__Frames {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-y: hidden;
    gap: 20px;
    margin-bottom: 50px;
    background-color: rgba(255, 255, 255, 1);
    // border: 1px solid red;
    .item {
      flex: 0 0 auto;
      // border: 1px solid red;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  //

  .Accessories__Your__EyeWear__title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 25px;
    color: rgba(172, 119, 43, 1);
    margin-bottom: 20px;
  }
  .Accessories__Your__EyeWear {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-y: hidden;
    gap: 20px;
    margin-bottom: 80px;
    background-color: rgba(255, 255, 255, 1);
    // border: 1px solid red;
    .item {
      flex: 0 0 auto;
      // border: 1px solid red;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

// .product-page-main-slider .slick-list{
//   height: 100px;
//   background-color: red;
// }