@use "./variables";
.App {
    // padding-top: 155px;
    width: 100vw;
    max-width: 100%;
    // @media screen and (max-width: variables.$small) {
    //     padding-top: 80px;
    // }
    // @media screen and (max-width: variables.$medium) {
    //     padding-top: 80px;
    // }
}
// *{
//   outline: red solid 1px;
// }

.no_product_found {
    width: 100%;
    height: 50dvh;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    font-size: 20px;
    color: #999999;
}
.Home-banner {
    height: calc(100vh - 150px);
    //height: 100vh;
    object-fit: cover;
}
.netre-logo {
    height: 35px;
}
.header {
    z-index: 10;
}
.header-top-child {
    margin: 0 25px;
    padding: 25px 0;
}

.header-right-icons .vertical-rule {
    width: 1px;
    border-right: 1px solid var(--text-color);
    height: 20px;
}
.header-right-icons .material-symbols-outlined {
    cursor: pointer;
    padding: 0 18px;
    height: 32px;
}
.header-right-icons .material-symbols-outlined:last-child {
    padding-right: 0;
}
.header-bottom {
    gap: 3%;
    padding: 20px 25px;
    color: var(--text-color);
    border-top: 1px solid var(--color1);
    .items {
        cursor: pointer;
        font-size: 18px;
    }
}

.BrowseGlasses {
    .heading {
        text-align: center;
        padding: 80px 0 0 0;
        font-size: 32px;
    }

}
.content {
    position: absolute;
    top: 20%;
    left: 5%;
    z-index: 1;
    .text-1 {
        color: var(--color2);
        text-transform: uppercase;
        font-size: 22px;
    }
    .text-2 {
        color: var(--text-color);
        text-transform: capitalize;
        font-size: 34px;
        margin: 25px 0;
    }
    .hr {
        border-bottom: 1px solid var(--color3);
        width: 50%;
        opacity: 0.5;
        margin: 25px 0;
    }
    .text-3 {
        color: var(--text-color);
        text-transform: capitalize;
        font-size: 16px;
        width: 70%;
        line-height: 1.8rem;
        margin: 25px 0;
    }
    .try-now-btn {
        font-size: 16px;
        padding: 12px 55px;
        text-transform: uppercase;
        color: var(--text-color);
        border: 1px solid var(--text-color);
        display: inline-block;
        position: relative;
        z-index: 3;
        cursor: pointer;
        .overlay {
            height: 50%;
            width: 50%;
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            transition: all 0.5s;
        }
    }
    .try-now-btn:hover {
        color: var(--primary-bg-color);
        .overlay {
            height: 100%;
            width: 100%;
            background: var(--text-color);
            border: 1px solid var(--text-color);
        }
    }
}
.bluerim {
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    background: transparent linear-gradient(138deg, #ebf2fb 0%, #f7f8fa 100%) 0% 0% no-repeat padding-box;
    .box {
        height: 50vh;
        width: 70vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        display: flex;
        .left {
            height: 100%;
            width: 50%;
            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                object-position: top right;
            }
        }
        .right {
            height: 100%;
            width: 50%;
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 6%;
            img {
                width: 50%;
            }
        }
    }
}
.Toastify__toast-body > div:last-child{
    color: #fff;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: #444444;
}
.filled {
    path {
    //   fill: var(--color2);
    }
  }
.Toastify__toast-body > div:last-child{
    color: #fff;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: #444444;
}

.card {
    aspect-ratio: 11/10;
    width: 100%;
    // background: var(--secondary-bg-color);

    position: relative;
    .sold-out {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: white;
      opacity: 0.75;
      display: flex;
      justify-content: center;
      align-items: start;
      padding-top: 15%;
      .div {
        color: var(--text-color-50);
        // transform: rotate(-45deg);
        transform-origin: center;
        font-size: 20px;
      }
    }
    .quantity {
      position: absolute;
      bottom: 20px;
      right: 20px;
      @media screen and (max-width: variables.$small) {
        right: 10px;
      }
      .label {
        font-size: 12px;
        margin-bottom: 5px;
        @media screen and (max-width: variables.$small) {
          display: none;
        }
      }
      select {
        border-radius: 1px;
        background: var(--primary-bg-color);
        border: none;
        padding: 10px;
        font-size: 14px;
        @media screen and (max-width: variables.$small) {
          padding: 5px;
          font-size: 10px;
        }
      }
    }
    .top {
      height: 15%;
      width: 100%;
      display: flex;
      justify-content: end;
      align-items: start;
      .new-arrival-label {
        // color: var(--color2);
        font-size: 14px;
        font-weight: 700;
        @media screen and (max-width: variables.$small) {
          font-size: 8px;
        }
      }
      .new-arrival-label {
        --f: 0.5em; /* control the folded part*/
        --r: 0.8em; /* control the ribbon shape */

        position: absolute;
        padding-inline: 0.25em;
        border-bottom: var(--f) solid #0005;
        border-right: var(--r) solid #0000;
        clip-path: polygon(
          0 0,
          0 calc(100% - var(--f)),
          var(--f) 100%,
          var(--f) calc(100% - var(--f)),
          100% calc(100% - var(--f)),
          calc(100% - var(--r)) calc(50% - var(--f) / 2),
          100% 0
        );
      }
      svg {
        height: 100%;
        width: 10%;
        path {
          // fill: var(--text-color);
        }
      }
      .filled {
        height: 100%;
        width: 10%;
        path {
          // fill: var(--color2);
        }
      }
      .count {
        color: var(--color2);
        font-size: 20px;
      }
      .hide {
        visibility: hidden;
      }
    }
    .middle {
      height: 70%;
      width: 100%;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
        vertical-align: middle;
      }
    }
    .bottom {
      // height: 15%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: end;
      // gap: 5px;
      .title {
        font-size: 16px;
        display: inline-block;
        width: calc(100%);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0px 0;
        @media screen and (max-width: variables.$small) {
          font-size: 12px;
        }
      }
      .sku {
        font-size: 14px;
        display: inline-block;
        width: calc(100%);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        visibility: hidden;
        cursor: pointer;
        @media screen and (max-width: variables.$small) {
          font-size: 12px;
        }
      }
      .qty-present {
        width: calc(70%);
      }
      .show {
        visibility: visible;
      }
    }
  }
