.BrowseGlasses {
    .heading {
        text-align: center;
        padding: 80px 0 0 0;
        font-size: 32px;
    }
}

@media screen and (max-width: 1000px) {
    .BrowseGlasses {
        .heading {
            text-align: center;
            padding: 80px 0 0 0;
            font-size: 32px;
        }
        .slider {
            max-width: 100vw;
            width: 100vw;
            display: block;
            // padding: 3%;
            gap: 0;
            .swiper {
                .swiper-wrapper {
                    .swiper-slide {
                        .slider-item {
                            width: 80vw;
                            height: 50vh;
                            padding-top: 50px;
                            overflow: hidden;
                            position: relative;
                            top: 0;
                            left: 0;
                            // border: 1px solid red;
                            cursor: pointer;
                            .img {
                                position: absolute;
                                bottom: 0;
                                left: 50%;
                                transform: translateX(-50%);
                                height: 100%;
                                width: 100%;
                                vertical-align: middle;
                                object-fit: cover;
                                object-position: 50% 100%;
                                transition:
                                    transform 0.4s ease,
                                    width 0.4s ease,
                                    height 0.4s ease;
                                z-index: 2;
                            }
                            .img-back {
                                position: absolute;
                                bottom: 0;
                                left: 50%;
                                transform: translateX(-50%);
                                height: 100%;
                                width: 100%;
                                vertical-align: middle;
                                object-fit: cover;
                                object-position: 50% 100%;
                                z-index: 1;
                            }
                            .overlay {
                                position: absolute;
                                height: 15%;
                                width: 100%;
                                background: transparent linear-gradient(180deg, #49474700 0%, #000000c9 100%) 0% 0%
                                    no-repeat padding-box;
                                bottom: 0;
                                left: 0;
                                z-index: 2;
                                transition: all 0.4s ease-in-out;
                            }
                            .text {
                                position: absolute;
                                top: 100%;
                                left: 50%;
                                z-index: 3;
                                color: var(--color4);
                                transform: translateX(-50%) translateY(-135%);
                                text-transform: uppercase;
                                padding: 10px;
                                border: 1px solid var(--color4);
                                transition: all 0.4s ease-in-out;
                                font-size: 14px;
                            }
                        }
                        .slider-item:hover {
                            // .img{
                            //     transform: translateX(-50%); /* Scale up by 10% on hover */
                            //     height: 120%;
                            //     // width: 120%;
                            // }
                            .overlay {
                            }
                            .text {
                            }
                        }
                    }
                }
            }
        }
    }
}
