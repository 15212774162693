.Favorites_Container{
    height: 100%;
    width: 100vw;
    max-width: 100%;
    .main_title{
        text-align: center;
        font-family: sabon;
        font-weight: 700;
    }
    .title{
        text-align: left;
    }
}