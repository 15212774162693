.Home-banner {
    height: calc(100vh - 150px);
    //height: 100vh;
    object-fit: cover;
}
.netre-logo {
    height: 35px;
}

.BrowseGlasses {
    .heading {
        text-align: center;
        padding: 80px 0 0 0;
        font-size: 32px;
    }
    .slider {
        max-width: 100vw;
        width: 100vw;
        display: flex;
        padding: 0% 3% 3% 3%;
        gap: 3%;
        .slider-item {
            width: 50%;
            aspect-ratio: 3/4;
            padding-top: 50px;
            overflow: hidden;
            position: relative;
            top: 0;
            left: 0;
            // border: 1px solid red;
            cursor: pointer;
            .img {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                vertical-align: middle;
                object-fit: contain;
                object-position: 50% 100%;
                transition:
                    transform 0.4s ease,
                    width 0.4s ease,
                    height 0.4s ease;
                z-index: 2;
            }
            .img-back {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 100%;
                width: 100%;
                vertical-align: middle;
                object-position: 50% 100%;
                z-index: 1;
            }
            .overlay {
                position: absolute;
                height: 15%;
                width: 100%;

                bottom: 0;
                left: 0;
                z-index: 2;
                transition: all 0.4s ease-in-out;
            }
            .text {
                position: absolute;
                top: 100%;
                left: 50%;
                z-index: 3;
                color: var(--color4);
                transform: translateX(-50%) translateY(0);
                text-transform: uppercase;
                padding: 10px;
                border: 1px solid var(--color4);
                transition: all 0.4s ease-in-out;
                font-size: clamp(8px, 1.5vw, 18px);
            }
            @media (min-width: 990px) and (max-width: 1024px) {
                .img {
                    height: 50%;
                }
            }
            @media (min-width: 1024px) and (max-width: 1100px) {
                .img {
                    height: 55%;
                }
            }
            @media (min-width: 1100px) and (max-width: 1150px) {
                .img {
                    height: 55%;
                }
            }
            @media (min-width: 1150px) and (max-width: 1200px) {
                .img {
                    height: 60%;
                }
            }
            @media (min-width: 1200px) and (max-width: 1250px) {
                .img {
                    height: 65%;
                }
            }
            @media (min-width: 1250px) and (max-width: 1300px) {
                .img {
                    height: 70%;
                }
            }
            @media (min-width: 1300px) and (max-width: 1480px) {
                .img {
                    height: 70%;
                }
            }
            @media screen and (min-width: 1480px) {
                .img {
                    height: 85%;
                }
            }
        }
        .slider-item:hover {
            // .img {
            //     transform: translateX(-50%); /* Scale up by 10% on hover */
            //     height: 150%;
            //     object-fit: contain;
            //     width: 100%;
            // }

            @media (min-width: 990px) and (max-width: 1024px) {
                .img {
                    height: 55%;
                }
            }
            @media (min-width: 1024px) and (max-width: 1100px) {
                .img {
                    height: 60%;
                }
            }
            @media (min-width: 1100px) and (max-width: 1150px) {
                .img {
                    height: 60%;
                }
            }
            @media (min-width: 1150px) and (max-width: 1200px) {
                .img {
                    height: 65%;
                }
            }
            @media (min-width: 1200px) and (max-width: 1250px) {
                .img {
                    height: 70%;
                }
            }
            @media (min-width: 1250px) and (max-width: 1300px) {
                .img {
                    height: 75%;
                }
            }
            @media (min-width: 1300px) and (max-width: 1480px) {
                .img {
                    height: 75%;
                }
            }
            @media (min-width: 1480px) and (max-width: 1550px) {
                .img {
                    height: 85%;
                }
            }
            @media screen and (min-width: 1550px) {
                .img {
                    height: 100%;
                }
            }

            .overlay {
                background: transparent linear-gradient(180deg, #49474700 0%, #000000c9 100%) 0% 0% no-repeat
                    padding-box;
            }
            .text {
                transform: translateX(-50%) translateY(-135%);
            }
        }
    }
}

.bluerim {
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    background: transparent linear-gradient(138deg, #ebf2fb 0%, #f7f8fa 100%) 0% 0% no-repeat padding-box;
    .box {
        height: 50vh;
        width: 70vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        display: flex;
        .left {
            height: 100%;
            width: 50%;
            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                object-position: top right;
            }
        }
        .right {
            height: 100%;
            width: 50%;
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 6%;
            img {
                width: 50%;
            }
        }
    }
}
